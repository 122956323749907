import { Controller } from "stimulus"
import { growl } from "../utils.js"

export default class extends Controller {
  static targets = [
    "body",
    "bodyField",
    "collapse", 
    "edit",
    "eventName",
    "recipient",
    "recipientsField",
    "subject",
    "subjectField",
    "trigger",
    "integration",   
    "url", 
    "urlField",
    "uuid"
  ]

  initialize() {
    this.baseUrl = '/api/triggers'
  }

  connect() {
    this.configureFields(this.integration)
  }

  get user() {
    return this.data.get('user')
  }

  get collapsed() {
    return (this.data.get("collapsed") === "true")
  }

  set collapsed(val) {
    this.data.set("collapsed", val)
  }

  get integration() {
    return this.data.get("integration")
  }

  set integration(val) {
    this.data.set("integration", val)
    this.configureFields(val)
  }

  get uuid() {
    return this.data.get("uuid")
  }

  set uuid(val) {
    this.data.set("uuid", val)
  }

  get recipients() {
    return this.recipientTargets.map((t) => (t.textContent))
  }

  handleIntegrationChange(e) {
    this.integration = e.target.value
  } 

  configureFields(integration) {
    switch(integration) {
      case "email":
        this.recipientsFieldTarget.classList.remove("d-none")
        this.subjectTarget.classList.remove("d-none")
        this.bodyTarget.classList.remove("d-none")
        this.urlTarget.classList.add("d-none")
        break;
      case "sms":
        this.recipientsFieldTarget.classList.remove("d-none")
        this.subjectTarget.classList.add("d-none")
        this.bodyTarget.classList.remove("d-none")
        this.urlTarget.classList.add("d-none")
        break;
      case "slack":
        this.recipientsFieldTarget.classList.add("d-none")
        this.subjectTarget.classList.add("d-none")
        this.bodyTarget.classList.remove("d-none")
        this.urlTarget.classList.add("d-none")
        break;
      case "webhook":
        this.recipientsFieldTarget.classList.add("d-none")
        this.subjectTarget.classList.add("d-none")
        this.bodyTarget.classList.remove("d-none")
        this.urlTarget.classList.remove("d-none")
        break;
      default:
        break;
    }
  }

  toggleCollapse(e) {
    e.preventDefault()
    if (this.collapsed) {
      this.expand()
    } else {
      this.collapse()
    }
  }

  collapse() {
    this.triggerTarget.querySelector(".card-body").classList.add("d-none")
    this.collapseTarget.querySelector(".fe").classList.add("fe-chevron-down")
    this.collapseTarget.querySelector(".fe").classList.remove("fe-chevron-up")
    this.collapsed = true
  }

  expand() {
    this.triggerTarget.querySelector(".card-body").classList.remove("d-none")
    this.collapseTarget.querySelector(".fe").classList.remove("fe-chevron-down")
    this.collapseTarget.querySelector(".fe").classList.add("fe-chevron-up")
    this.collapsed = false
  }

  toJSON() {
    return {
      name: this.eventNameTarget.textContent,
      uuid: this.uuid,
      integration: this.integration,
      template: {
        subject: this.subjectFieldTarget.value,
        url: this.urlFieldTarget.value,
        body: this.bodyFieldTarget.value,
        recipients: this.recipients,
      },
      user_id: this.user
    }
  }

  async handleSave(e) {
    if (e.target.textContent == 'Edit') {
      try {
        await this.save()
        growl({
          message: 'Saved!',
          category: 'success'
        })
      } catch (e) {
        growl({
            message: e.message,
            category: 'danger',
            error: e
        })
      }
    }
  }

  async save() {
    const { uuid, ...d } = this.toJSON()
    const method = (uuid === '') ? 'POST': 'PATCH'
    const endpoint = (uuid === '') ? '' : `/${uuid}`
    const url = `${this.baseUrl}${endpoint}`
    const response = await fetch(url, { 
      method,
      body: JSON.stringify(d),
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    })
    if (!response.ok) {
      console.log(await response.text())
      throw Error(`Network issues: ${response.status}`)
    }
    const json = await response.json()
    this.uuid = json.id
    return json
  }
}