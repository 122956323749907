import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["alert"]

  connect() {
    setTimeout(() => { this.dismiss(this.alertTarget) }, 3000)
  }

  handleClose(e) {
    this.dismiss(e.target.parentNode)
  }

  dismiss(notification) {
    notification.classList.add('hidden')
    setTimeout(() => { notification.remove() }, 500)
  }
}