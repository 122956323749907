import { Controller } from "stimulus"
import { growl } from "../utils.js"

export default class extends Controller {
    static targets = ["triggerlist", "trigger", "newTrigger", "integration", "save", "form"]

    connect() {
      this.newTriggerTarget.classList.remove("d-none")
    }

    get user() {
      this.data.get("user")
    }

    handleAdd(e) {
      e.preventDefault()
      let clone = document.importNode(document.querySelector("#triggerTemplate").content, true)
      const ix = this.triggerTargets.length
      const firstChild = this.triggerlistTarget.children[0]

      let input = clone.querySelectorAll(".selectgroup-input")
      input.forEach((t) => { t.name = `value-${ix}` })
      this.triggerlistTarget.insertBefore(clone, firstChild)
      // TODO: ugh, this is gross but it's because the MutationObserver is asynchronous
      setTimeout(() => {this.collapseAllButFirst()}, 10)
    }

    collapseAllButFirst() {
      this.triggerTargets.forEach((t, ix) => {
        const trigger = this.application.getControllerForElementAndIdentifier(t, "trigger")
        if (ix > 0) {
          trigger.collapse()
        } else {
          trigger.expand()
        }
      })
    }

    serializeTriggers() {
      return this.triggerTargets.map((t) => { 
        const trigger = this.application.getControllerForElementAndIdentifier(t, "trigger")
        return trigger.toJSON()
      })
    }

    
    async submitAll(data) {
      try {
        
      } catch(e) {
        throw Error(e.message)
      }
    }

    async handleSubmit(e) {
      e.preventDefault()
      this.saveTarget.innerHTML = `<i class="fe fe-radio mr-1"></i> Saving`
      this.saveTarget.classList.remove("btn-primary")
      this.saveTarget.classList.add("btn-info")
      try {
        for (let index = 0; index < this.triggerTargets.length; index++) {
          const t = this.triggerTargets[index]
          const trigger = this.application.getControllerForElementAndIdentifier(t, "trigger")
          await trigger.save(this.formTarget.action)
        }
        growl({
          message: 'Saved!',
          category: 'success'
        })
      } catch (e) {
        growl({
            message: e.message,
            category: 'danger',
            error: e
        })
      }
      
      this.saveTarget.innerHTML = "Save"
      this.saveTarget.classList.remove("btn-info")
      this.saveTarget.classList.add("btn-primary")
    }
}