import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["input", "field", "taglist"]

  parseTag() {
    const val = this.inputTarget.value
    const label = (val.slice(-2) == ", ") ? val.slice(0, -2) : val
    if (this.validate(label)) {
      this.addTag(label)
      this.inputTarget.value = ""
    }
  }

  addTag(label) {
    let clone = document.importNode(document.querySelector("#closableTagTemplate").content, true)
    clone.querySelector(".tag-label").textContent = label
    this.taglistTarget.appendChild(clone)
  }

  validate(val) {
    return val.trim() != ""
  }

  handleKeypress(e) {
    if (e.charCode == 13) {
      e.preventDefault()
      this.parseTag()
    }
  }

  handleInput(e) {
    if (e.type === "change" || e.target.value.slice(-2) == ", ") {
      this.parseTag()
    }
  }
}