import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["label", "field", "edit"]

  connect() {
    this.editTarget.classList.remove("d-none")
    if (this.editable) {
      this.enable()
    }
  }

  get editable() {
    return (this.data.get("editable") === "true")
  }

  set editable(val) {
    this.data.set("editable", val)
  }

  handleClick(e) {
    if (this.editable) {
      this.disable()
    } else {
      this.enable()
    }
  }

  enable() {
    this.labelTarget.contentEditable = true
    this.editTarget.textContent = "Save"
    let selection = window.getSelection()
    let range = document.createRange()
    range.selectNodeContents(this.labelTarget)
    selection.removeAllRanges()
    selection.addRange(range)
    this.editable = true
  }

  disable() {
    this.labelTarget.contentEditable = false
    this.editTarget.textContent = "Edit"
    let selection = window.getSelection()
    selection.removeAllRanges()
    this.editable = false
  }

  updateField(e) {
    this.fieldTarget.value = this.labelTarget.textContent
  }
}