import '@babel/polyfill'
import { Application } from "stimulus"
import { definitionsFromContext } from "stimulus/webpack-helpers"

// import 'bootstrap'
// import './core.js'
import './scss/app.scss'

const application = Application.start()
const context = require.context("./controllers", true, /\.js$/)
application.load(definitionsFromContext(context))