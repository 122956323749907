import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["radio"]

  get value() {
    return this.data.get("value")
  }

  set value(val) {
    this.data.set("value", val)
  }

  save(e) {
    console.log(e.target.value)
  }
}